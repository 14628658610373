<template>
  <v-card flat>
    <v-card-title primary-title>
      <h4 class="headline text-center">{{ name }}</h4>
      <v-spacer></v-spacer>
      <h4 class="text-center">Each: {{ unitPrice | currency }}</h4>
    </v-card-title>
    <v-img
      v-if="imageUrl && !hideChef"
      @click.native="showDetail"
      :src="imageUrl"
      contain
      height="200px"
    ></v-img>

    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12>
          <v-alert
            border="left"
            colored-border
            color="deep-purple accent-4"
            elevation="2"
            class="ma-0 py-0"
          >
            <v-col sm="2" class="title">{{ $t('Common.order_qty') }} : {{ item.orderAmount }}</v-col>
            <v-card-text v-if="item.modifiers">
              <span class="font-weight-light">Modifiers:</span>
              <view-modifiers :modifiers="item.modifiers" />
            </v-card-text>

            <template v-if="false">
              <v-icon
                small
                v-if="
                item.orderAmount > item.dish.amountLeft &&
                  item.dish.amountLeft != -1
              "
                color="red"
                class="mr-1"
              >error</v-icon>
              <v-col sm="2">
                <span class="fs-14">{{ $t('Chef.available_qty') }}</span>
                : {{ getAmount(item.dish.amountLeft) }}
              </v-col>
            </template>
            <v-col sm="8" v-if="item.specialInstruction">
              <em class="info--text">Note:</em>
              {{ item.specialInstruction }}
            </v-col>
            <v-col sm="2" v-if="false">
              {{ $t('Common.sub_total') }}:
              {{ getSubTotal(item) | currency }}
            </v-col>
          </v-alert>
        </v-flex>
      </v-layout>
    </v-card-text>
    <template v-if="!hideChef">
      <v-card-actions v-if="chef">
        Served by: {{ chef.name }}
        <v-avatar size="36px" slot="activator" class="mx-2">
          <img :alt="chef.name" v-if="chefAvatar" :src="chefAvatar" />
        </v-avatar>
        <v-btn text color="purple" :to="'/chef/' + chef.id">Profile</v-btn>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="show = !show">
          <v-icon>
            {{
            show ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
            }}
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-slide-y-transition v-if="chef">
        <v-card-text v-show="show">{{ chef.description }}</v-card-text>
      </v-slide-y-transition>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { FoodMixin } from "fod-core/src/mixins/foodMixin";
import ViewModifiers from "../dish/ViewModifiers";
export default {
  props: ["hideChef", "item"],
  mixins: [FoodMixin],
  components: { ViewModifiers },
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapGetters({
      food: "food",
      chef: "chef",
      isChef: "isChef"
    }),
    dish() {
      return this.item ? this.item.dish : null;
    }
  },
  methods: {
    showDetail() {
      this.$router.push("/dish/" + this.dish.id);
    },
    getAmount(amount) {
      return amount == -1 ? "Unlimited" : amount;
    }
  }
};
</script>
