<template>
  <v-container fluid v-if="!loading && myOrder">
    <v-row>
      <v-col cols="12" offset-sm="1" sm="8">
        <v-card-text v-if="myOrder.system_messages">
          <v-icon color="red" large>error_outline</v-icon>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(message, key) in myOrder.system_messages" :key="key"
            >
              <v-expansion-panel-header>
                <div
                  :class="message.type ? message.type + '--text' : 'red--text'"
                >
                  {{ message.title }} : {{ $moment(message.created).fromNow() }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-alert type="error" outline :value="true">
                  {{ message.message }}
                </v-alert>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-col>
    </v-row>

    <v-row v-if="myOrder.products">
      <template v-for="item in myOrder.products">
        <v-col cols="12" sm="10" md="8" offset-sm="1" :key="item.dish.id">
          <food-view :hideChef="false" :item="item" />
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8" offset-sm="1">
        <v-list two-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-row>
                  <v-col cols="8" lg="10">
                    Order #:
                    <small>{{ myOrder.id }}</small>
                  </v-col>
                  <v-col cols="3" lg="2" v-if="track_status">
                    {{ track_status }}
                    <span v-if="track_url">
                      <a :href="track_url" target="_blank">Track</a>
                    </span>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col cols="11" v-if="myOrder.chefResponse">
                  <v-card flat>
                    <small>
                      <v-card-text class="px-0">
                        Chef Note:
                        <span class="red--text">{{
                          myOrder.chefResponse
                        }}</span>
                      </v-card-text>
                    </small>
                  </v-card>
                </v-col>
                <v-col cols="11" v-if="myOrder.notes">
                  <v-card flat>
                    <small>
                      <v-card-text class="px-0">
                        Buyers Note:
                        <span class="fs-16 blue--text">{{
                          myOrder.notes
                        }}</span>
                      </v-card-text>
                    </small>
                  </v-card>
                </v-col>
                <!-- cancellation note -->
                <v-col cols="11" v-if="myOrder.buyerResponse">
                  <v-card flat>
                    <small>
                      <v-card-text class="px-0">
                        Cancellation Reason:
                        <span class="fs-16 red--text">{{
                          myOrder.buyerResponse | truncate(250)
                        }}</span>
                      </v-card-text>
                    </small>
                  </v-card>
                </v-col>
              </v-row>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text
                :class="icon(myOrder.status).color + '--text'"
                class="status_title"
              >
                {{ title(myOrder.status) | capitalize }}
                <br />
                <small>{{ myOrder.error }}</small>
                <small class="info--text">{{ myOrder.cooking_status }}</small>
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-divider />

          <order-fee
            :cart="myOrder.products"
            :chef="chef"
            :all_fees="myOrder.payment.all_fees"
            :deliveryMethod="myOrder.deliveryMethod"
            :deliveryCharge="myOrder.delivery.deliveryFee"
            :payment="myOrder.payment"
          />
          <v-divider />

          <v-list-item>
            <v-list-item-content v-if="myOrder.delivery">
              <v-list-item-title>
                Delivery Method:
                <span class="info--text">{{
                  myOrder.deliveryMethod | capitalize
                }}</span>
                -
                <span
                  class="indigo--text small"
                  v-if="myOrder.delivery.deliveryEstimate"
                >
                  <small>
                    Estimated:
                    {{
                      (myOrder.delivery.deliveryEstimate.fee / 100) | currency
                    }}
                  </small>
                </span>

                <span v-if="myOrder.delivery_track">
                  (
                  <a
                    :href="myOrder.delivery_track.tracking_url"
                    target="_blank"
                    >{{ myOrder.delivery_track.status }}</a
                  >
                  )
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    v-if="myOrder.delivery.pickup_address"
                  >
                    Pickup Address:
                    {{ myOrder.delivery.pickup_address }}
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    v-if="myOrder.delivery.dropoff_address"
                  >
                    Delivery Address:
                    {{ myOrder.delivery.dropoff_address }}
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-list-item-title>
                <v-row>
                  <v-col cols="12" sm="6" v-if="myOrder.delivery.dropoff_name"
                    >Dropoff name: {{ myOrder.delivery.dropoff_name }}</v-col
                  >
                  <v-col
                    cols="12"
                    sm="6"
                    v-if="myOrder.delivery.dropoff_phone_number"
                  >
                    Dropoff phone:
                    {{ myOrder.delivery.dropoff_phone_number }}
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Delivery Date: {{ myOrder.deliveryDate | formatDate }} @
                {{ myOrder.deliveryTime | formatTime }}
              </v-list-item-title>
              <v-list-item-title v-if="deliveryTime">
                Delivery Date: {{ deliveryDateTime }} ({{
                  deliveryDateTimeFromNow
                }})
              </v-list-item-title>
              <v-list-item-subtitle class="text--primary">
                Order date:
                {{ myOrder.orderDate | formatDateTime }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text></v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="!userProfile && chef" :to="'/chef/' + chef.id">
            <v-list-item-content>
              <v-list-item-title>Chef: {{ chef.name }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ chef.email }}
                <v-icon>mail</v-icon>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="chef.phone">
                {{ chef.phone }}
                <v-icon>phone</v-icon>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar>
              <img :src="chef.profilePicture" />
            </v-list-item-avatar>
          </v-list-item>
          <v-divider />

          <v-card>
            <v-card-actions>
              <v-btn small v-if="canCancel" color="error" @click="showCancel">
                Cancel Order
                <v-icon dark right>cancel</v-icon>
              </v-btn>

              <template v-if="!canCancel && myOrder.status != 'delivered'">
                <div>
                  <p>
                    *Please Contact the Producer if Order Needs to be Cancelled.
                  </p>
                  <p v-if="myOrder.seller.businessPhone">
                    Producer Number: {{ myOrder.seller.businessPhone }}
                  </p>
                </div>
              </template>

              <template v-if="myOrder.status === 'delivered'">
                <v-btn
                  color="info"
                  small
                  :to="'/review/order/' + myOrder.id"
                  raised
                  class="mx-3"
                >
                  Write a review
                  <v-icon dark right>thumb_up</v-icon>
                </v-btn>
              </template>

              <!-- <template v-if="myOrder.status === 'confirmed'">
                <v-btn
                  color="info"
                  small
                  @click.stop="setStatus('picking_up')"
                  raised
                  >{{ deliveryTitle }}
                  <v-icon dark right>local_shipping</v-icon>
                </v-btn>
                <v-btn color="secondary" small
                  >{{ noDeliveryTitle }}
                  <v-icon dark right>block</v-icon>
                </v-btn>
              </template>-->

              <template v-if="allowArchive(myOrder.status)">
                <v-btn small dark @click.prevent="archive" color="info">
                  Archive
                  <v-icon dark right>folder</v-icon>
                </v-btn>
              </template>
              <template v-if="myOrder.status === 'delivered'">
                <v-btn color="info" small @click.stop="reorder">Reorder</v-btn>
              </template>
            </v-card-actions>
            <!-- refund message-->
            <template
              v-if="
                myOrder.payment_status &&
                  (myOrder.payment_status === 'refunding' ||
                    myOrder.payment_status === 'refunded')
              "
            >
              <div class="mt-3">
                <p
                  v-if="myOrder.payment_status === 'refunding'"
                  class="grey--text font-weight-thin font-italic"
                >
                  refunding in process
                </p>
                <p
                  v-else-if="myOrder.payment_staus === 'redfunded'"
                  class="grey--text font-weight-thin font-italic"
                >
                  Refunded Successfully
                  <br />
                  Reason: {{ myOrder.refund.message }}
                </p>
              </div>
            </template>
          </v-card>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="show = !show">
              <v-icon>{{
                show ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
              }}</v-icon>
            </v-btn>
          </v-card-actions>
        
        </v-card>
      </v-col>
      <v-dialog v-model="cancelOrRejectDialog" persistent max-width="390">
        <v-card>
          <v-card-title
            >{{ cancelOrRejectStatus | capitalize }} Order</v-card-title
          >
          <v-card-text>
            <v-text-field label="Reason" v-model="response"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click.stop="cancelOrRejectDialog = false"
              >Close</v-btn
            >
            <v-btn dark color="red" @click.stop="cancelOrRejectOrder">
              Submit
              <v-icon dark right>cancel</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-btn color="primary" @click="$router.go(-1)" icon>
      <v-icon>chevron_left</v-icon>
    </v-btn>
    <v-dialog v-model="showOrderConfirm" max-width="320">
      <v-card color="red">
        <v-card-title class="headline white--text">Confirm</v-card-title>

        <v-card-text>
          <span class="white--text">
            Your cart already has products, do you want to start a new one?
          </span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            class="white--text"
            @click="onClickModalYes"
            >Yes</v-btn
          >
          <v-btn
            class="white--text"
            color="red darken-1"
            @click="onClickModalNo"
            >No</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import FoodView from 'fod-core/src/components/order/OrderFoodView.vue'

//import { OrderMixin } from '@/mixins/orderMixin'
import { OrderMixin } from 'fod-core/src/mixins/orderMixin'
import { FoodMixin } from 'fod-core/src/mixins/foodMixin'
import OrderFee from 'fod-core/src/components/order/OrderFee.vue'
import moment from 'moment'

export default {
  name: 'ViewOrder',
  mixins: [FoodMixin, OrderMixin],
  components: {
    FoodView,
    OrderFee
  },
  data: () => ({
    show: false,
    cancelOrRejectDialog: false,
    cancelOrRejectStatus: null,
    response: null,
    deliveryDate: null,
    buyer: null,
    observer: null,
    showOrderConfirm: false
  }),
  computed: {
    ...mapGetters({
      cartInfo: 'cart',
      myOrder: 'order',
      user: 'user',
      // dish: 'dish',
      chef: 'chef',
      userProfile: 'userProfile',
      loading: 'loading'
    }),
    primaryPhone() {
      return this.userProfile.businessPhone
        ? this.userProfile.businessPhone
        : this.userProfile.phone
    },
    primaryEmail() {
      return this.userProfile.businessEmail
        ? this.userProfile.businessEmail
        : this.userProfile.email
    },
    chef() {
      return this.myOrder.seller
    },
    foodPhoto() {
      return this.dish && this.dish.photos && this.dish.photos.length > 0
        ? this.dish.photos[0].imageUrl
        : null
    },

    deliveryTitle() {
      if (
        this.myOrder.delivery &&
        this.myOrder.delivery.delivery_method === 'pickup'
      ) {
        return 'Picked up'
      }
      return 'Delivered'
    },
    noDeliveryTitle() {
      if (
        this.myOrder.delivery &&
        this.myOrder.delivery.delivery_method === 'pickup'
      ) {
        return 'No pick up'
      }
      return 'No Delivery'
    },
    deliveryDateTime() {
      if (this.myOrder) {
        return moment(
          this.myOrder.deliveryDate + ' ' + this.myOrder.deliveryTime,
          'YYYY-MM-DD HH:mm'
        ).format('MM/DD/YYYY hh:mm')
      }
    },
    deliveryDateTimeFromNow() {
      if (this.myOrder) {
        return moment(
          this.myOrder.deliveryDate + ' ' + this.myOrder.deliveryTime,
          'YYYY-MM-DD HH:mm'
        ).fromNow()
      }
    },
    canCancel() {
      if (this.myOrder && this.myOrder.status) {
        // if producer has not yet accepted order
        if (
          this.myOrder.status === 'submitting' ||
          this.myOrder.status === 'submitted'
        ) {
          return true
        }
        // if producer has accepted but is scheduled for more than 24 from now
        else if (
          this.myOrder.status === 'confirmed' &&
          !this.scheduledInLessThan24Hours
        ) {
          return true
        }
      }
      return false
    },
    track_url() {
      return this.myOrder.delivery && this.myOrder.delivery.delivery_track
        ? `https://postmates.com/track/${this.myOrder.delivery.delivery_track.id}`
        : null
    },
    track_status() {
      return this.myOrder.delivery
        ? this.myOrder.delivery.delivery_status
        : null
    },
    // returns true if order is scheduled less than 24 from now
    scheduledInLessThan24Hours() {
      let time_from_order =
        (this.myOrder.deliveryDateTime - moment().valueOf()) /
        (1000 * 60 * 60 * 24)
      return time_from_order < 24
    }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer()
    }
  },
  mounted: async function() {
    let source = this.$route.query.source
    console.log(
      `Loading order: ${this.$route.params.order_id} ,  source:${source}`
    )

    let orderId = this.$route.params.order_id
    if (orderId) {
      this.$store.dispatch('loadOrder', { orderId, source }).then(observer => {
        this.observer = observer
        this.loadOrder()
      })
    }
  },
  methods: {
    gotoCart() {
      this.$store.dispatch('updateCartReOrder', {
        uid: this.myOrder.uid,
        orderId: this.myOrder.id
      })
      setTimeout(() => {
        this.$router.push({
          name: 'Cart',
          params: { isReorder: true }
        })
      }, 3000)
    },
    onClickModalYes() {
      this.showOrderConfirm = false
      this.$store.dispatch('emptyCart')
      this.gotoCart()
    },
    onClickModalNo() {
      this.showOrderConfirm = false
    },
    loadOrder() {
      if (this.myOrder) {
        /* this.$gtm.trackEvent({
          event: 'orderSubmission',
          transactionId: this.myOrder.orderId,
          transactionAffiliation: this.myOrder.foodName,
          transactionTotal: this.myOrder.totalPrice,
          transactionTax: 1.29,
          transactionShipping: 5,
          transactionProducts: [
            {
              sku: this.myOrder.foodName,
              name: this.myOrder.foodName,
              category: this.myOrder.foodType,
              price: 1,
              quantity: this.myOrder.orderAmount
            }
          ]
        }) */

        if (!this.myOrder.seller) {
          if (this.myOrder.chefId && this.user.id !== this.myOrder.chefId) {
            this.$store.dispatch('loadChefById', this.myOrder.chefId)
            this.buyer = true
          }
        }

        if (
          this.myOrder.buyerUserId &&
          this.user.id !== this.myOrder.buyerUserId
        ) {
          this.buyer = false
          this.$store.dispatch('loadUserProfile', {
            uid: this.myOrder.buyerUserId
          })
        }
      }
    },
    setStatus(payload) {
      let request = {
        response: this.response,
        id: this.$route.params.order_id,
        status: payload
      }
      if (payload === 'delivering' || payload === 'picking_up') {
        request.deliveryDate = new Date().getTime()
      }
      console.log('Submitting', request)
      this.$store.dispatch('setOrderStatus', request)
      this.$router.push('/order/list')
    },
    acceptOrder() {
      let request = {
        response: this.response,
        id: this.$route.params.order_id,
        status: 'accepting'
      }
      this.$store.dispatch('setOrderStatus', request)
      this.$router.push('/order/list')
    },
    showCancel() {
      this.cancelOrRejectStatus = 'cancelling'
      this.cancelOrRejectDialog = true
    },
    showReject() {
      this.cancelOrRejectStatus = 'rejecting'
      this.cancelOrRejectDialog = true
    },
    cancelOrRejectOrder() {
      let request = {
        response: this.response,
        id: this.$route.params.order_id,
        status: this.cancelOrRejectStatus
      }
      this.$store.dispatch('setOrderStatus', request)
      this.$router.push('/order/list')
      this.cancelOrRejectDialog = false
    },
    archive() {
      console.log('Archiving', this.myOrder)
      let request = {
        id: this.$route.params.order_id,
        archive: true
      }
      this.$store.dispatch('setOrderStatus', request).then(() => {
        this.$router.push('/order/list')
      })
    },
    reorder() {
      //check for cart is empty
      console.log(this.cartInfo.cart, 'cart length!!!!!!!!!!!')
      if (this.cartInfo.cart && this.cartInfo.cart.length > 0) {
        this.showOrderConfirm = true
      } else {
        this.gotoCart()
      }
    }
  }
}
</script>
<style scoped>
.status_title {
  font-size: 1em;
}
</style>
